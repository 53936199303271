//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  请求模板列表
    getTableData(params) {
        return axios({
            url:'/workTemplate/selectTemplate',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  新增模板
    addNewData(data) {
        return axios({
            url:'/workTemplate/insertTemplate',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    //  修改模板
    upDataModel(data) {
        return axios({
            url:`/workTemplate/updateByTemplate`,
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    changeInUseStatus(data) {
        return axios({
            url:'/workTemplate/updateTemplateStatus',
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            data
        })
    },
    deleteModel(params) {
        return axios({
            url:'/workTemplate/deleteTemplate',
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            },
            params
        })
    },
    getDicByPid(params) {
        return axios({
            url:'/dict/selectDictListByPid',
            method:'GET',
            headers:{
                'Content-Type':'application/json'
            },
            params
        })
    }
}