<template>
  <div>
    <div class="contain1">
      <div class="searchBox">
        <el-form class="searchBoxForm" :inline="true" size="small">
          <el-form-item label="模板名称" class="searchItem">
            <el-input
              v-model="pageParams.templateName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="工程类型" class="searchItem">
            <el-select
              v-model="pageParams.projectType"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in projectTypeDic"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" class="searchItem">
            <el-select
              v-model="pageParams.templateStatus"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in templateStatusDic"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" size="mini" @click="handleCurrentChange(1)"
            >查询</el-button
          >
        </el-form>
      </div>
    </div>
    <div class="contain2">
      <div class="tableTool">
        <el-button type="primary" plain @click="onAddNewTemplate" size="mini"
          >添加</el-button
        >
      </div>
      <el-table
        :data="tableData"

        border
        height="calc(100vh - 362px)"
        stripe
        :header-cell-style="{ background: '#f8f8f9' }"
      >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="center"
          width="50"
        >
        </el-table-column>
        <el-table-column
          v-for="(item, index) in tableHeader"
          :key="index"
          :label="item.label"
          :prop="item.property"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.column.property === 'inUse'">

              <el-tag :type="scope.row[scope.column.property] == 1?'info':'success'"> {{
                scope.row[scope.column.property] == 1 ? "未被使用" : "已被使用"
                }}</el-tag>
            </div>
            <div v-else-if="scope.column.property === 'projectType'">
              {{
                compontedProjectTypeName(
                  scope.row[scope.column.property],
                  projectTypeDic
                )
              }}
            </div>
            <div v-else-if="scope.column.property === 'templateStatus'">
              <el-tag :type="scope.row[scope.column.property] == 1?'success':'danger'"> {{
                scope.row[scope.column.property] == 1 ? "启用" : "停用"
                }}</el-tag>
            </div>
            <div v-else>
              {{ scope.row[scope.column.property] || "-" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="150">
          <template slot-scope="scope">
            <div class="operateTool">
              <el-button
                type="danger" size="small"
                v-if="scope.row.templateStatus == 1"
                @click="changeInUseStatus(scope.row, 2)"
                >停用</el-button
              >
              <el-button
                type="success" size="small"
                v-if="scope.row.templateStatus == 2"
                @click="changeInUseStatus(scope.row, 1)"
                >启用</el-button
              >
              <el-button size="small" type="primary" @click="onShowContract(scope.row)"
                >查看</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[ 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
      <el-dialog
        :title="updataStatus === 1 ? '新增模板' : '修改模板'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        @closed="onCancelDialog"
        width="60%"
      >
        <el-form
          :model="form"
          :rules="rules"
          ref="addForm"
          :disabled="!isUpdata"
          label-width="100px"
          label-position="left"
        >
          <div class="miniTitle">
            模板信息<span class="isInUse" v-if="updataStatus != 1">{{
              form.inUse == 1 ? "未被使用" : "已被使用"
            }}</span>
          </div>
          <el-form-item label="模板名称" prop="templateName">
            <el-input
              v-model="form.templateName"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="工程类型" prop="projectType">
            <el-select
              v-model="form.projectType"
              class="form-select"
              placeholder="请选择"
            >
              <el-option
                v-for="item in projectTypeDic"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="templateStatus">
            <el-select
              v-model="form.templateStatus"
              class="form-select"
              placeholder="请选择"
            >
              <el-option
                v-for="item in templateStatusDic"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="miniTitle">项目明细</div>
          <el-form-item prop="teamType" label="施工队类型">
            <el-select
              v-model="form.teamType"
              class="form-select"
              placeholder="请选择"
            >
              <el-option
                v-for="item in teamTypeDic"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-button type="primary" plain @click="addNewNode">添加</el-button>
          <el-table
            :data="form.templateUnit"

            border
            height="300px"
            stripe
            :header-cell-style="{ background: '#f8f8f9' }"
          >
            <el-table-column
              label="序号"
              align="center"
              width="50"
              type="index"
              :index="indexfun"
            >
            </el-table-column>
            <el-table-column
              v-for="(item, index) in dialogTableHeader"
              :key="index"
              :label="item.label"
              :prop="item.property"
              :width="item.width ? item.width : ''"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.sortNum === editSort">
                  
                    <div v-if="scope.column.property == 'unitName'">
                    <el-form-item
                      label-width="0"
                      :prop="`templateUnit.${scope.$index}.unitName`"
                      :rules="rules.unitName"
                    >
                      <el-input
                        v-model="scope.row[scope.column.property]"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div v-else-if="scope.column.property == 'unitMeasurement'">
                    <el-form-item
                      label-width="0"
                      :prop="`templateUnit.${scope.$index}.unitMeasurement`"
                      :rules="rules.unitMeasurement"
                    >
                      <el-input
                        v-model="scope.row[scope.column.property]"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div v-else>
                    <el-form-item
                      label-width="0"
                      :prop="`templateUnit.${scope.$index}.unitNecessary`"
                      :rules="rules.unitNecessary"
                    >
                      <el-select
                        v-model="scope.row[scope.column.property]"
                        placeholder="请选择"
                      >
                        <el-option
                          v-for="item in unitNecessaryDic"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div v-else>
                  <div v-if="scope.column.property == 'unitNecessary'">
                    {{
                      scope.row[scope.column.property] == 1
                        ? "是"
                        : scope.row.unitNecessary == 2
                        ? "否"
                        : "-"
                    }}
                  </div>
                  <div v-else>
                    {{ scope.row[scope.column.property] || "-" }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="left" width="200">
              <template slot-scope="scope">
                <div class="operateTool" v-if="editSort == scope.row.sortNum">
                  <el-button type="text" @click="onSaveSort(scope.$index)"
                    >保存</el-button
                  >
                  <el-button type="text" @click="onCancelSort(scope.$index)"
                    >取消</el-button
                  >
                </div>
                <div class="operateTool" v-else>
                  <el-button type="text" @click="onDeleteSort(scope.$index)"
                    >移除</el-button
                  >
                  <el-button type="text" @click="onChangeEdit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    :disabled="scope.row.sortNum == 1"
                    @click="onTurnUp(scope.row.sortNum)"
                    >上移</el-button
                  >
                  <el-button
                    type="text"
                    :disabled="scope.row.sortNum == form.templateUnit.length"
                    @click="onTurnDown(scope.row.sortNum)"
                    >下移</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="onCancelDialog">取 消</el-button>
          <el-button type="primary" @click="onSaveAdd" v-if="updataStatus == 1"
            >保 存</el-button
          >
          <el-button
            type="primary"
            @click="isUpdata = true"
            v-if="!isUpdata && updataStatus == 2 && form.inUse != 2"
            >编 辑</el-button
          >
          <el-button
            type="primary"
            @click="onSaveUpdata"
            v-if="isUpdata && updataStatus != 1"
            >保 存</el-button
          >
          <el-button
            type="danger"
            @click="onDeleteModel"
            v-if="updataStatus == 2 && form.inUse == 1"
            >删 除</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import pageMixins from "@/utils/pageMixins";
import axios from "@/apis/systemApi";
import { getDict } from "@/apis/commonType";
export default {
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        templateName: "",
        projectType: "",
        templateStatus: "",
        current: 1,
        size: 100,
      }, //  分页查询
      tableHeader: [
        { label: "模板名称", property: "templateName" },
        { label: "工程类型", property: "projectType" },
        { label: "创建人", property: "realname" },
        { label: "创建时间", property: "createTime" },
        { label: "使用状态", property: "inUse",width:"100" },
        { label: "状态", property: "templateStatus",width:"100"  },
      ], //  表头数据
      tableData: [], //  表格数据
      templateStatusDic: [
        { label: "启用", code: 1 },
        { label: "停用", code: 2 },
      ], //  状态字典
      projectTypeDic: [], //  类型字典
      dialogVisible: false, //  弹窗开关
      form: {
        templateName: null,
        projectType: null,
        templateStatus: 1,
        teamType: null,
        templateUnit: [
          {
            sortNum: 1,
            unitMeasurement: "",
            unitName: "",
            unitNecessary: "",
          },
        ],
      }, //  表单
      editSort: 1, //  编辑的序号
      rules: {
        templateName: [
          { required: true, message: "请输入", trigger: "blur" },
          { max: 100, message: "超出限制", trigger: "change" },
        ],
        projectType: [{ required: true, message: "请选择", trigger: "change" }],
        teamType: [{ required: true, message: "请选择", trigger: "change" }],
        templateStatus: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        unitMeasurement: [
          { required: true, message: "请输入", trigger: "blur" },
          { max: 100, message: "超出限制", trigger: "change" },
        ],
        unitName: [
          { required: true, message: "请输入", trigger: "blur" },
          { max: 100, message: "超出限制", trigger: "change" },
        ],
        unitNecessary: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      }, //  规则
      updataStatus: 1, //  修改状态
      dialogTableData: [], //  弹窗表格内容
      dialogTableHeader: [
        { label: "项目名称", property: "unitName" },
        { label: "单位", property: "unitMeasurement" },
        { label: "必选项", property: "unitNecessary" },
      ], //  弹窗表格表头
      unitNecessaryDic: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ], //  是否必填字典
      teamTypeDic: [
        { label: "安装", value: 1 },
        { label: "土方", value: 2 },
        { label: "定向钻", value: 3 },
      ], //  施工队类型字典
      isUpdata: false, //  修改
      activeName: "first", //  tab切换选择
      stroeRow: {}, //  修改缓存的内容
    };
  },
  computed: {
    /**计算名称 */
    compontedProjectTypeName: () => {
      return (id, projectTypeDic) => {
        let item = projectTypeDic.find((e) => e.id == id);
        return item ? item.name : "-";
      };
    },
    
  },
  mounted() {
    this.getProjectTypeDic();
    this.loadList();
  },
  methods: {
    indexfun(index){
      return index + 1
    },
    /**获取列表 */
    async loadList() {
      let { pageParams } = this;
      try {
        let res = await axios.getTableData(pageParams);
        this.tableData = res.data.records;
        this.total = res.data.total;
      } catch (e) {
        this.tableData = [];
      }
    },
    /**停用启用 */
    async changeInUseStatus(row, type) {
      let { id } = row;
      let data = {
        id,
        templateStatus: type,
      };
      try {
        await axios.changeInUseStatus(data);
        this.$message.success("修改成功");
        this.loadList();
      } catch (e) {}
    },
    /**点击查看按钮 */
    onShowContract(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.form.projectType = this.form.projectType;
      this.updataStatus = 2;
      this.dialogVisible = true;
    },
    /**工程量模板 */
    onAddNewTemplate() {
      this.dialogVisible = true;
      this.updataStatus = 1;
      this.isUpdata = true;
    },
    /**获取工程类型字典 */
    async getProjectTypeDic(pid) {
      try {
        let res = await getDict({ parentCode: 2100 });
        this.projectTypeDic = res.data;
      } catch (e) {
        this.projectTypeDic = [];
      }
    },
    /**切换状态 */
    onChangeEdit(row) {
      this.editSort = row.sortNum;
      this.stroeRow = JSON.parse(JSON.stringify(row));
    },
    /**保存sort */
    onSaveSort(index) {
      let isArr = [];
      this.$refs.addForm.validateField(
        [
          `templateUnit.${index}.unitMeasurement`,
          `templateUnit.${index}.unitName`,
          `templateUnit.${index}.unitNecessary`,
        ],
        (err) => {
          isArr.push(!Boolean(err));
        }
      );
      if (isArr.some((e) => !e)) return;
      this.editSort = null;
      this.stroeRow = {};
    },
    /**取消保存 */
    onCancelSort(index) {
      let { stroeRow } = this;
      this.form.templateUnit[index] = JSON.parse(JSON.stringify(this.stroeRow));
      this.stroeRow = {};
      this.editSort = null;
      this.isUpdata = false;
    },
    /**取消关闭弹窗 */
    onCancelDialog() {
      this.form = {
        templateName: null,
        projectType: null,
        templateStatus: 1,
        teamType: null,
        templateUnit: [
          {
            sortNum: 1,
            unitMeasurement: "",
            unitName: "",
            unitNecessary: "",
          },
        ],
      };
      this.$refs.addForm.resetFields();
      this.dialogVisible = false;
      this.isUpdata = false;
    },
    async onSaveUpdata() {
      let isVaild = await this.$refs.addForm.validate();
      if (!isVaild) return;
      let { form } = this;
      try {
        await axios.upDataModel(form);
        this.$message.success("修改成功");
        this.dialogVisible = false;
        this.isUpdata = false;
        this.loadList();
      } catch (e) {}
    },
    /**删除节点 */
    onDeleteSort(index) {
      let { templateUnit } = this.form;
      if (templateUnit.length == 1)
        return this.$message.warning("至少保留一项");
      templateUnit = templateUnit.filter((e, i) => i != index);
      this.form.templateUnit = templateUnit.map((e, i) => {
        e.sortNum == i + 1;
        return e;
      });
      this.onInitSortNum();
    },
    /**上移 */
    onTurnUp(index) {
      let { templateUnit } = this.form;
      this.form.templateUnit = templateUnit.map((e) => {
        let { sortNum } = e;
        if (sortNum == index - 1) {
          e.sortNum = index;
        } else if (sortNum == index) {
          e.sortNum = index - 1;
        }
        return e;
      });
      this.onInitSortNum();
    },
    /**下移 */
    onTurnDown(index) {
      let { templateUnit } = this.form;
      this.form.templateUnit = templateUnit.map((e) => {
        let { sortNum } = e;
        if (sortNum == index) {
          e.sortNum = index + 1;
        } else if (sortNum == index + 1) {
          e.sortNum = index;
        }
        return e;
      });
      this.onInitSortNum();
    },
    /**初始化sort */
    onInitSortNum() {
      let { templateUnit } = this.form;
      this.form.templateUnit = templateUnit.sort((a, b) => {
        return a.sortNum - b.sortNum;
      });
    },
    /**新增节点 */
    addNewNode() {
      let { templateUnit } = this.form;
      let obj = {
        sortNum: templateUnit.length + 1,
        unitMeasurement: "",
        unitName: "",
        unitNecessary: "",
      };
      this.form.templateUnit.push(obj);
      this.$nextTick(() => {
        this.editSort = obj.sortNum;
      });
    },
    async onSaveAdd() {
      let isVaild = await this.$refs.addForm.validate();
      if (!isVaild) return;
      let { form } = this;
      try {
        await axios.addNewData(form);
        this.$message.success("新增成功");
        this.onCancelDialog();
        this.loadList();
      } catch (e) {}
    },
    onDeleteModel() {
      this.$confirm("此操作将永久删除该模板, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      })
        .then(async () => {
          let { id } = this.form;
          console.log(this.form);
          try {
            await axios.deleteModel({ id });
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.dialogVisible = false;
            this.loadList();
          } catch (e) {}
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.contain1 {
  .searchBox {
    .searchBoxForm {
      display: flex;
      align-items: center;
      .searchItem {
        margin-bottom: 0px;
        margin-right: 17px;
      }
    }
  }
}
.contain2 {
  .tableTool {
    display: flex;
    margin-bottom: 10px;
    justify-content: flex-end;
    .infoTitle {
      flex: 1;
      font-size: 18px;
    }
    .rightButtonTool {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }
}
.miniTitle {
  font-size: 16px;
  margin-bottom: 15px;
  color: #303133;
  &::before {
    content: "";
    display: inline-block;
    height: 16px;
    width: 3px;
    margin-right: 5px;
    vertical-align: middle;
    background-color: #1082ff;
  }
}
.form-select {
  width: 100%;
}
.isInUse {
  margin-left: 20px;
  color: #f00;
  text-decoration: underline;
}
</style>